import { Actions, pageViewObservable } from '@rategravity/frontend/modules/user-actions';
import { OwnUpThemeWrapper } from '@rategravity/own-up-component-library-legacy';
import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Route } from 'react-router';
import Rx from 'rxjs/Rx';
import { ActionPipe } from '../hooks/use-action-pipe';
import { firstPartyTrackingObserver } from '../modules/1pt-observer';
import { googleObserver } from '../modules/conversions/google/google-observer';
import { RatesAppWrapper } from './app';
import { store } from './redux';
import { history } from './redux/router';

const actionPipe = new Rx.ReplaySubject<Actions>(10);
// Route page view actions to the action pipe
pageViewObservable(history).subscribe(actionPipe);

actionPipe.subscribe(firstPartyTrackingObserver());
actionPipe.subscribe(googleObserver());

export const App = render(
  <React.Fragment>
    <Provider store={store}>
      <OwnUpThemeWrapper className="content">
        <ConnectedRouter history={history}>
          <ActionPipe value={actionPipe}>
            <div>
              <Route
                path="/rates/:id"
                render={({
                  match: {
                    params: { id }
                  }
                }) => <RatesAppWrapper rootPath={`/rates/${id}`} id={id} />}
              />
            </div>
          </ActionPipe>
        </ConnectedRouter>
      </OwnUpThemeWrapper>
    </Provider>
  </React.Fragment>,
  document.getElementById('root')
);
